html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

body.homepage {
  background-color: #ffffff;
}

h1::after {
  content: "";
  display: block;
  height: 2px;
  width: 80px;
  border-radius: 1px;
  background-color: #bb262a;
  margin-top: 16px;
  margin-bottom: 18px;
}

.homepage .top, 
.privacy-policy .top {
  z-index: 10;

  .logo {
    margin-top: 22px;
    margin-bottom: 16px;
  }
  .flag {
    margin-top: 5px;
    margin-left: 20px;
  }
  .form-login {
    position: absolute;
    top: 60px;
    right: 0;
    z-index: 3;
    padding: 32px 40px;
    border-radius: $radius;
    background-color: #ffffff;
    box-shadow: $darker-shadow;
  }
  button.is-active {
    background-color: #ffffff;
    color: $secondary-color;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.3);
  }
  button,
  .button {
    // padding-top: 10px;
    // padding-bottom: 10px;
    margin-top: 0;
    margin-bottom: 0;

    &.is-link {
      text-decoration: none;

      &:hover {
        color: $primary-color;
      }
    }
  }
  .nav-group {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 18px;
  }

  &.sticky {
    position: fixed !important;
    left: 0px;
    top: 0px;
    right: 0px;
    background-color: #fff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);

    .logo {
      margin-top: 17px;
      margin-bottom: 10px;
      height: 35px;
    }
    button,
    .button {
      padding-top: 10px;
      padding-bottom: 10px;
      margin-top: 0;
      margin-bottom: 0;
    }
    .flag {}
    .form-login {
      top: 45px;
    }
  }
}

.header {
  height: 400px;
  display: flex;
  align-items: center;
  background-image: url("../img/image-header-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  .slogan {
    font-family: "PT Serif";
    font-size: 54px;
    line-height: 62px;
    margin-bottom: 60px;
    max-width: 13em;
    position: relative;
    z-index: 2;
  }
  .cta {
    margin-bottom: 8px;
    color: #fff;
  }
  .slogan,
  .cta,
  img {
    display: block;
  }
  .mask {
    display: block;
    height: 400px;
    width: 420px;
    position: absolute;
    right: 0;
  }

  @-webkit-keyframes scrollpulse {
    0% {
      -webkit-transform: translateY(0%);
    }
    50% {
      -webkit-transform: translateY(100%);
    }
    100% {
      -webkit-transform: translateY(0%);
    }
  }

  .scroll-icon {
    -webkit-animation: scrollpulse 3s infinite ease-in;
    -moz-animation: scrollpulse 3s infinite ease-in;
    -o-animation: scrollpulse 3s infinite ease-in;
    animation: scrollpulse 3s infinite ease-in;
  }
}

.solutions {
  padding-top: 110px;
  padding-bottom: 40px;

  .level-1 {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .service-logo {
    margin-bottom: 24px;
  }
}

.services {
  padding-bottom: 110px;

  .level-2 {
  }
  .level-2 .padded {
    padding-bottom: 110px;
    padding-right: 32px;
    button {
      position: absolute;
      bottom: 32px;
    }
  }
  .level-2 .bordered {
    padding-left: 24px;
    padding-right: 32px;
  }
  .service-logo {
    margin-bottom: 24px;
  }
  .level-3 {
    padding-top: 80px;
    .container {
      display: flex;
      justify-content: space-between;
    }

    .sub-service {
      opacity: 0.6;
      display: inline-flex;
      font-family: "PT Serif";
      font-size: 21px;
      letter-spacing: -0.28px;
      line-height: 25px;
      text-transform: uppercase;
      background-image: url(../img/triangle-black-left.svg);
      background-repeat: no-repeat;
      background-position: right 8px;
      padding-right: 24px;
      margin-right: 48px;
    }
  }
  .image-system {
    position: absolute;
    right: 0;
  }
}

@media (max-width: $breakpoint-lg) {
  .services {
    .container {
      flex-direction: column;
    }
    .sub-service {
      display: inline-block;
      margin-bottom: 16px;
    }
    .level-2 .bordered {
      border-left: 0;
      border-right: 0;
      padding-left: 16px;
      padding-right: 24px;
    }
  }
}

.approach {
  padding-top: 110px;
  padding-bottom: 110px;
  background-color: $lighter-gray;
  .intro {
    margin-bottom: 64px;
  }
  .row > div {
    margin-bottom: 32px;
  }
  .card {
    border-radius: $radius;
    background-color: #ffffff;
    box-shadow: $shadow;
    padding: 55px 100px 15px 60px;
    margin-bottom: 32px;
    background-image: url(../img/triangle-blue-right.svg);
    background-repeat: no-repeat;
    background-position: left 63px;
    cursor: pointer;
    // height: 100%;
    display: block;
    transition: $transition;

    .title {
      font-family: "PT Serif";
      font-size: 28px;
      line-height: 37px;
      display: block;
      margin-bottom: 8px;
    }
    .hint {
      opacity: 0;
      transition: $transition;
      display: block;
      margin-top: 30px;
      font-size: 0.75rem;
      color: $secondary-color;
    }
    .description {
      font-size: 16px;
      line-height: 24px;
      display: block;
      /*width: 80%;*/
    }
    &:hover {
      box-shadow: $darker-shadow;

      .hint {
        opacity: 1;
      }
    }
    &.is-active {
      color: #ffffff;
      background: $gradient-blue;
      box-shadow: $large-shadow;
      position: absolute;
      z-index: 2;
      margin-top: -48px;
      margin-left: -10px;
      padding: 55px 100px 55px 80px;
      height: auto;

      .hint {
        display: none;
      }
    }
  }
}

@media (max-width: $breakpoint-md) {
  .approach {
    .card {
      &.is-active {
        position: relative;
        margin: -12px 0 24px 0;
      }
    }
  }
}

.team {
  padding-top: 110px;
  padding-bottom: 110px;
  display: flex;
  align-items: center;

  .intro {
    margin-bottom: 48px;
  }
  .person {
    .photo {
      float: left;
      padding-right: 10px;
      margin-left: -4px;
      margin-bottom: 40px;
      filter: saturate(70%);
      transition: 200ms;

      &:hover {
        filter: saturate(110%);
      }
    }
    .details {
      .name {
        display: block;
        font-family: "PT Serif";
        font-size: 20px;
        line-height: 27px;
        margin-top: 28px;
        margin-bottom: 4px;
      }
      .position {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .team {
    .person {
      margin-bottom: 32px;
      .photo {
        float: none;
        display: block;
        margin-bottom: 0;
      }
      .details {
        .name {
          margin-top: 0;
        }
      }
    }
  }
}

.newsletter {
  padding-top: 110px;
  padding-bottom: 110px;
  min-height: 50vh;
  display: flex;
  align-items: center;

  .newsletter-content {
    background-color: #fff;
    border-radius: $radius;
    box-shadow: $shadow;
    padding-top: 48px;
    padding-bottom: 48px;
    padding-left: 32px;
    padding-right: 32px;
    color: $gray;
  }
  h2 {
    margin-bottom: 8px;
  }
  p {
    font-size: 14px;
    line-height: 22px;
  }
  .bordered {
    border-left: $border;
  }
  .email {
    display: block;
    margin-top: 32px;
    margin-bottom: 32px;
    background-image: url(../img/email.svg);
    background-repeat: no-repeat;
    background-position: left top;
    padding: 5px 0 5px 42px;
    font-weight: 700;
  }
  .newsletter-form {
    margin-top: -8px;
  }
  .newsletter-form input {
    min-width: 60%;
    margin-right: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .newsletter-form .checkbox-agree {
  }

  .newsletter-form .checkbox {
    padding-left: 0;
    margin-left: 0;
    min-width: auto;
  }

  .newsletter-form .checkbox-agree label {
    display: inline;
    text-transform: none;
    font-size: 0.65rem;
    line-height: 0.5rem;
  }
}

.footer {
  z-index: 20000;
  .level-1 {
    background-color: #2b2b2b;
    color: #d9d9d9;
    padding-top: 48px;
    padding-bottom: 32px;
    h1 {
      color: #fff;
      font-size: 28px;
      line-height: 37px;
    }
    .logo {
      float: right;
      margin-top: 8px;
    }
  }
  .level-2 {
    background-color: #313131;
    color: #6a6a6a;
    font-size: 11px;
    line-height: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.popup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;

  .popup-bg {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &.wide .popup-body { width: 700px !important; }
  .popup-body {
    max-height: 700px;
    overflow-y: auto;
    display: block;
    background-color: #ffffff;
    padding: 32px;
    color: $gray;
    border-radius: $radius;
    box-shadow: $shadow;
    position: relative;
    z-index: 100;
    transition: $transition-reveal;
    width: 500px;

    h2 {
      margin-bottom: 24px;
    }
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
	  background: rgba(#888, 0.5);
	  border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
	  background: rgba(#888, 0.7);
    }
  }
  img.popup-close {
    position: absolute;
    right: 24px;
    top: 36px;
    padding: 8px;
    cursor: pointer;
    background-color: rgba(255,255,255,.8);
  }
}

.privacy-policy-content {
  margin-top: 110px;
  margin-bottom: 110px;
  h2 {
    margin-top: 40px;
  }
  hr {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

.cookies {
  padding: 10px 0 12px;
  background-color: #eee;
  display: none;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;

  .container {
    p {
      text-align: center;
      font-size: 0.75em;
      line-height: 1.5em;
      color: $gray;
      margin-bottom: 0;
      padding: 1em 0;

      a {
        text-decoration: underline;
        color: $gray;
      }

      #cookie {
        img {
          width: 12px;
          height: 12px;
          float: right;
          cursor: pointer;
          position: relative;
          margin-top: 4px;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-md) {
  .popup-container {
    display: block;
    overflow-y: scroll;

    .popup-body {
      display: block;
      width: 80%;
      margin: 24px auto;
    }
  }
}

.access-request-message {
  display: block;
  font-size: 14px;
  line-height: 1.4;
  padding: 16px 16px 8px 16px;
  border-radius: 3px;
  margin-bottom: 1em;

  .title {
    display: block;
    font-size: inherit;
    font-weight: bold;
    margin-bottom: 0.5em;
  }

  p {
    font-size: inherit;
    line-height: inherit;
  }

  a {
    font-size: inherit !important;
    line-height: inherit;
  }

  &__success { background-color: $success-color-light; }
  &__fail { background-color: $fail-color-light; }
}

section.testimonials {
  // min-height: 50vh;
  padding-top: 3rem;
  padding-bottom: 1rem;
  color: #FFF;

  .testimonials-section-title {
    margin-bottom: 70px;
  }

  .slick-arrow {
    display: flex;
    align-items: center;
    background-color: transparent;
    background-position: right center;
    background-repeat: no-repeat;
    box-shadow: none;
    position: absolute;
    top: 45%;

    &:hover {
      cursor: pointer;
    }
  }

  .slick-prev {
    left: -80px;


  }
  .slick-next {
    right: -80px;
  }

  .testimonials-container {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .testimonials-content {
      
      color: $gray;
      border-radius: 4px; 
      position: relative;

      .quote-icon { 
        position: absolute;
        top: -45px;
        right: -85px;
        z-index: 0;
        opacity: 0.1;
        width: 100px;
      }

      .slides-container {
        background-color: #FFF;
        position: relative;
        padding: 50px 100px 50px 100px;
        border-radius: 4px; 
        display: flex;
        flex-direction: row;
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
        width: 100%;

        @media screen and (max-width: 1200px) { width: 800px; }
        @media screen and (max-width: $breakpoint-lg) { width: 600px; }
        @media screen and (max-width: $breakpoint-md) { width: 450px; padding: 50px 70px 50px 70px; }
        @media screen and (max-width: $breakpoint-sm) { width: 300px; padding: 50px 50px 50px 50px; }


        .slide { 
          width: 100%;
          

          .testimonial-text {
            font-family: 'PT Serif', serif;
            font-size: 21px;
            line-height: 30px;
            align-self: stretch;
          }

          .testimonial-footer {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-end;

            @media screen and (max-width: 680px) {
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-start;
            }

            .testimonial-logo {
              margin-top: 50px;

              img {
                height: 40px;
              }
            }

            .testimonial-type {
              display: flex;
              flex-direction: row;
              margin-top: 30px;
              
              // .ornament {
              //   display: block;
              //   width: 39px;
              //   height: 40px;
              //   background-image: url("../img/klienci/testimonial-slide-type-bg.svg"); 
              //   background-repeat: no-repeat;
              // }

              .name {
                font-size: 14px;
                font-weight: 600;
                color: $secondary-color;
                padding: 12px 18px;
                background-color: #E8EAF6;
                border-radius: 20px;
                text-align: center;
              }
            }
          }

          
        }
      }

      ul.slick-dots {
          display: flex;
          flex-direction: row;
          justify-content: center;
          flex-wrap: wrap;
          margin-left: auto;
          margin-right: auto;

          @media screen and (max-width: 1200px) { width: 800px; }
          @media screen and (max-width: $breakpoint-lg) { width: 600px; }
          @media screen and (max-width: $breakpoint-md) { width: 350px; }
          @media screen and (max-width: $breakpoint-sm) { width: 300px; }

          li {
            button {
              background-color: transparent;
              box-shadow: none;
              background-position: center;
              background-image: url("../img/klienci/icons/dot_active.svg"); 
              background-repeat: no-repeat;
              color: transparent;
            }
            &.slick-active {

              button {
                background-image: url("../img/klienci/icons/dot_unactive.svg"); 
              }
            }
          }
        }
    }

  }
}

section.clients {
  padding-top: 3rem;
  padding-bottom: 5rem;
  min-height: 50vh;

  .logos-container {

    .logos-movable {
      // width: 130%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: -30px;
      margin-right: -30px;

      // -webkit-animation-name: movelogos; 
      // -webkit-animation-duration: 60s; 
      // animation-name: movelogos;
      // animation-duration: 60s;
      // animation-iteration-count: infinite;
      // animation-delay: 2s;
      // animation-timing-function: linear;

      /* Safari 4.0 - 8.0 */
      // @-webkit-keyframes movelogos {
      //   0% { transform: translateX(0px); }
      //   50% { transform: translateX(-240px); }
      // }

      /* Standard syntax */
      // @keyframes movelogos {
      //   0% { transform: translateX(0px);  }
      //   50% { transform: translateX(-240px); }
      // }

      .logo {

        height: 80px;
        // margin-right: 90px;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: 160px;
        flex-shrink: 1;
        flex-grow: 1;
        max-width: 160px;
        margin-left: 30px;
        margin-right: 30px;
        text-align: center;

        img {
          // height: 40px;
          max-height: 40px;
          max-width: 160px;
          // width: 185px;
          opacity: 0.6;
          transition: 250ms all;

          &:hover {
            opacity: 1;
            transform: scale(1.2);
          }
        }

      }

    }
  }
}

