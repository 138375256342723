@import "../variables";

.popup-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9;
  .popup-bg {
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .popup-lg {
    width: 60%;
  }
  .popup-body {
    display: block;
    width: 60%;
    background-color: #FFFFFF;
    padding: 32px;
    color: $gray;
    border-radius: $radius;
    box-shadow: $shadow;
    position: relative;
    z-index: 100;
    overflow-y: scroll;
    overflow-x: hidden;
    transition: all 300ms ease-out;
    h2 {
      margin-bottom: 24px;
    }
    &.hide {
      margin-top: 48px;
    }

    @media (max-width: $breakpoint-md) {
      width: 100%;
    }

    &.filter-body,
    &.error-body {
      // width: 60%;
    }

    .options-group {
      // border: 1px solid #EEE;
      background-color: #e8eaf6;
      padding: 8px;
      margin-bottom: 8px;
      border-radius: 4px;
    }

    a {
      font-size: 14px;
      font-weight: 600;
    }

    li.popup-remove-item {
      border: 1px solid #E8EAF6;
      border-radius: $radius;
      padding: 9px 40px 10px 14px;
      font-size: 14px;
      text-align: left;
      margin-bottom: 8px; 
      position: relative;

      img.trash {
        position: absolute;
        right: 0;
        top: 0;
        padding: 12px 15px 15px 14px;
        cursor: pointer;
      }

      .options-group {
        margin-top: 8px;

        p {
          font-size: 12px;  
          line-height: 18px;  
        }
        
      }
    }

    ul.list {
      margin-bottom: 20px;
      height: 300px;
      overflow-y: scroll;
      overflow-x: hidden;

      li {
        display: block; 
        padding: 6px 4px 0 8px;
        border: 1px solid $light-blue;
        border-radius: 3px;
        margin-bottom: 4px;

        label {
          display: block; 
        }
      }
    }

    ul.accordion {
      .acc-body {
        label {
          margin-right: 12px;
        }
      }
    }

  }
  img.popup-close {
    position: absolute;
    right: 20px;
    top: 36px;
    padding: 8px;
    cursor: pointer;
  }

  .filter-section, .section {
    margin-bottom: 25px;
    margin-right: 10px;
    display: inline-block;

    &__header {
      display: block;
      text-transform: uppercase;
      font-weight: bold;
      color: #777777;
      font-size: 12px;
      line-height: 18px;
      margin-bottom: 8px;
    }

    label {
      color: black;
      text-transform: none;
    }

    input {
      margin: 0 4px 0 12px;
    }

    // .input-icon {
    //   display: inline-block;
    //   width: 130px;
    //   position: relative;
    //   margin: 0 10px;

    //   input {
    //     width: 100%;
    //     height: 30px;
    //     margin: 0;
    //   }

    //   img {
    //     position: absolute;
    //     right: 10px;
    //     top: 50%;
    //     transform: translateY(-50%);
    //     width: 16px;
    //     pointer-events: none;
    //   }
    // }

    .inline-input {
      display: inline-block;
    }
  }

  .error-section {
    .button-section {
      display: flex;
      justify-content: flex-start;
    }
  }

  hr {
    background: $table-border;
    height: 1px;
    border: none;
  }

  .button-section {
    display: flex;
    // justify-content: flex-end;
    // margin-left: -10px;
    // margin-right: -10px;

    button {
        margin-left: 0;
        margin-right: 24px;
    }
  }

  .info-box {
    border-radius: $radius;
    border: 1px solid #E8EAF6;
    background-color: #FAFAFA;
    padding: 16px;
  }
}