@import "../variables";
.clearfix {
  &:before, &:after {
    content: " ";
    display: table;
  }
}

.container {
  &:before, &:after {
    content: " ";
    display: table;
  }
}

.container-fluid {
  &:before, &:after {
    content: " ";
    display: table;
  }
}

.row {
  &:before, &:after {
    content: " ";
    display: table;
  }
}

.clearfix:after, .container:after, .container-fluid:after, .row:after {
  clear: both;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.center {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}


@media (max-width: $breakpoint-sm) { .hidden-sm-down { display: none !important; } }
@media (min-width: $breakpoint-sm) { .hidden-sm-up { display: none !important; } }

@media (max-width: $breakpoint-md) { .hidden-md-down { display: none !important; } }
@media (min-width: $breakpoint-md) { .hidden-md-up { display: none !important; } }

.affix {
  position: fixed;
}

.gradient--red {
  background: $gradient-red;
  color: white; 
}
.gradient--blue {
  background: $gradient-blue;
  color: white; 
}
.area {
  background-color: white; 
  border-radius: $radius; 
  border: 1px solid #F1F1F1;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.05);
  padding: $padding-div $padding-div-md; 
}

section.full-height {
  min-height: 100vh;
}