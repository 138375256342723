@import "../variables";

.top-menu {
  background: linear-gradient(90deg,#911C42 0,#CF3D6C 100%);
  // padding: 10px 0;

  .container {
    // padding-left: 30px;
    // padding-right: 30px;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    // overflow: hidden;

    li {
      float: left;
      // padding: 5px 1px;


      a {
        // height: 48px;
        font-size: 0.9em;
        display: block;
        padding: 12px 0;
        margin: 0 15px;
        padding: 20px 0;
        // border-radius: 7px;
        color: white;
        border-bottom: 4px solid transparent;

        &.active {
          // background-color: rgba(white, 0.2);
          border-bottom: 4px solid rgba(white, 0.8);
        }

        &:hover {
          // background-color: rgba(white, 0.2);
          border-bottom: 4px solid rgba(white, 0.6);
          text-decoration: none;
        }

        &.home-icon {
          line-height: 3em;
        }

        &.has-new-content::after {
          content: " ";
          display: inline-block;
          border-radius: 50%;
          background-color: #FFF;
          width: 8px;
          height: 8px;
          margin-left: 6px;
          margin-top: -8px;
          position: relative;
          top: -6px;
        }

      }

      .home-icon {
        padding: 7px 0px;
        margin-top: 7px;
        img {
          width: 24px;
        }
      }
    }
  }

  &__search {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    height: 40px;
    margin-top: 13px;

    input[type="text"] {
      font-size: 13px;
      border: none;
      float: left;
      width: 100%;
      background: white;
      border-radius: 0;
      height: 40px;

    }

    button {
      height: 40px;
      margin: 0;
      border-radius: 0;
      float: left;
      width: 12%;
      padding: 10px 10px 10px 10px;
      background: white;
      box-shadow: none;
      border: none;
      cursor: pointer;

      position: absolute;
      right: 16px;
      border-radius: 5px;

      img {
        width: 16px;
        opacity: 0.7;
      }

      &:hover {
        background: white;
        img {
          opacity: 0.7;
        }
      }
    }

    &::after {
      content: "";
      clear: both;
      display: table;
    }

  }

}

.top-sub-menu {
  border-bottom: 1px solid $menu-bg;
  height: 30px;

  &__sublink {
    font-size: 14px;
    display: none;
    padding-top: 7px;
  }
}