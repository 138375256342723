.auth-section {
  padding-top: 75px;
  padding-bottom: 75px;
  min-height: 76vh;

  @media (max-width: $breakpoint-sm) {
    padding-top: 40px;
  }
  &__header {
    margin-bottom: 20px;

    h2 {
      padding: 5px 0;
      margin-bottom: 0;
    }

    .line::after {
      content: "";
      display: block;
      height: 2px;
      width: 80px;
      border-radius: 1px;
      background-color: #bb262a;
      margin-top: 16px;
      margin-bottom: 0;
    }
  }

  .go-back {
    font-weight: bold;
    margin-bottom: 30px;
    display: block;
  }

  .auth-box {
    border: 1px solid #e0e0e0;
    padding: 40px;
    margin-bottom: 40px;

    .form-login {

      &__title {
        margin-bottom: 30px;
        font-size: 1.4em;
      }
      label {
        text-transform: uppercase;
        font-weight: bold;
        color: #777777;
        display: block;
        .remember-me {
          transform: scale(1.2);
        }
      }
    }
  }

  .interests-box {
    margin-top: 60px;

    .form-login {
      h3 {
        // color: #777777;
        font-size: 14px;
        line-height: 1.4em;
        font-weight: bold;
        font-family: inherit;
        margin-top: 20px;
        margin-bottom: 15px;
      }
      label {
        line-height: 1.4em;
        text-transform: none;
        color: black;
        font-weight: normal;
      }

      &__row-bottom {
        font-size: 1em;
        margin-top: 40px;
        select {
          margin-top: 20px;
          width: 300px;
          @media (max-width: $breakpoint-sm) {
            width: 100%;
          }
        }
      }
    }
  }

  .info-box {
    @media (max-width: $breakpoint-sm) {
      padding-top: 40px;
    }
    &__header {
      margin-left: 20px;
    }

    p {
      margin-left: 20px;
    }

    .info-list {
      list-style: none;
      margin-left: 0;
      margin-bottom: 2em;
      padding-left: 1.2em;

      li {
        margin-bottom: 10px;
        line-height: 1.4em;
        display: flex;
        vertical-align: middle;

        .el-icon {
          width: 32px;
          height: 32px;
          padding-right: 30px;
          background: url(../img/tick.png) no-repeat;
          background-size: 20px 20px;
          margin-top: 2px;
          margin-right: 10px;
        }

        .el-content {
        }

      }
    }
  }

  &.thank-you {
    height: 70vh;
  }

}

.reg-link {
  display: block;
  padding: 25px;

  &--big {
    font-size: 1.1em;
  }
}

.register-message {
  font-size: 1.1em;
  line-height: 1.8em;
}

.resend {
  font-size: 0.8em;
  display: block;
  text-decoration: underline;
  font-weight: bold;
  margin-top: 10px;

  &:hover {
    text-decoration: none;
  }
}

