@import "../variables";

input,
select,
textarea {
  padding: $padding $padding-l;
  border: 1px solid #E0E0E0;
  width: auto;
  margin-bottom: 1rem;
  border-radius: $radius;
  outline: none;

  &.error {
    padding-right: 30px;
    border-color: $primary-color;
    background-image: url(../img/cancel.svg);
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 12px 12px;

  }
}

input::placeholder,
textarea::placeholder {
  color: $light-gray;
}

input[disabled=disabled] { background-color: #EEE; }

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */

input.full-width,
textarea.full-width {
  width: 100%;
}

input[type='checkbox'] {
  margin-bottom: 0;
}

input[type='text'], 
input[type='email'],
input[type='password'], 
select {
  height: 40px;
}
select {
  font-size: 0.8em;
}

// BUTTONS

button,
.button {
  padding: 0.75rem 1.5rem;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  border-radius: $radius;
  background-color: $secondary-color;
  border: none;
  margin-top: 10px;
  margin-bottom: 1rem;
  outline: none;
  box-shadow: 0 0 16px 0 lighten($gray, 70%);
  cursor: pointer;
  transition: $transition;

  &.no-margin {
    margin-left: 0;
    margin-right: 0;
  }

  &--small {
    margin: 0 0 0 30px;
    padding: 8px 20px;
    border-radius: 5px;
  }

  &--upload {
    margin: 0;
    // padding: 10px 20px;
    // border-radius: 5px;
    padding: 8px 16px;
    color: $secondary-color;
    border: 0;
    font-family: inherit;
    font-weight: underline;
    font-size: 14px;
    color: #FFF;
    // margin-left: -8px;
    margin: 8px 0;
    
    overflow: hidden;
    max-width: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  &--link {
    margin: 0 0 0 15px;
    background-color: transparent;
    color: $secondary-color;
    text-decoration: underline;
    box-shadow: none;
    border: none;
    padding: 0;

    &:hover {
      background-color: transparent !important;
      text-decoration: none;
    }
  }

  &--empty {
    background-color: transparent;
    color: $secondary-color;
    text-decoration: underline;
    box-shadow: none;
    border: none;

    &:hover {
      background-color: transparent;
      text-decoration: none;
    }
  }

  &--light {
    color: #333;
    background: white;
    border: 1px solid $border-gray;
    box-shadow: none;
    // margin-left: 10px;

    &:hover {
      background: $border-gray !important;
    }
  }

  &.has-border {
    color: $secondary-color;
    background: transparent;
    border: 1px solid $secondary-color;
    box-shadow: none;

    &:hover {
      background: white !important;
    }
  }

}

button:hover,
.button:hover {
  background-color: $darker-secondary-color;
}

button:active,
.button:active {
  box-shadow: 0 0 2px 0 lighten($gray, 70%);

}

button:disabled,
button[disabled],
.button:disabled,
.button[disabled] {
  opacity: 0.5;
  box-shadow: 0 0 0 0;
  cursor: default;
}

button.is-secondary,
.button.is-secondary {
  background-color: $primary-color;
  &:hover {
    background-color: $darker-primary-color;
  }
}

button.is-tertiary,
.button.is-tertiary {
  color: $black;
  background-color: white;
  &:hover {
    background-color: $darker-white;
  }
}

button.is-link,
.button.is-link {
  color: $secondary-color;
  background-color: transparent;
  box-shadow: 0 0 0 0;
  &:hover {
    color: $darker-secondary-color;
    text-decoration: underline;
  }
}

.is-link {
  font-weight: 600;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }

  &--big {
    display: block;
    padding: 20px 0;
    font-size: 0.8em;
    text-decoration: underline;
    font-weight: bold;

    &:hover {
      text-decoration: none;
    }
  }
}

.uloaded-file-name {
  display: block;
  font-size: 11px;
  color: rgba(0,0,0,.75);
  line-height: 16px;
}

form {
  
  .popup-container & {
    p, a { font-size: 12px; line-height: 18px; }
  }

  a { 
    text-decoration: underline; 
    font-weight: 600; 
  }

  fieldset {
    margin-bottom: 16px; 
  }
}

form, .form-login {
  select {
    background: white;
    width: 100%;
  }

  &__errormessage {
    color: $primary-color !important;
    font-size: 0.7em;
    line-height: 150%;
    margin-bottom: 2em;
  }

  .form-help {
    display: inline-block;
    margin-top: 60px;
    transition: all .2s linear;
    a {
      display: inline-block;
      margin-top: 16px;
      margin-right: 24px;
      background-repeat: no-repeat;
      background-size: 24px 24px;
      padding-left: 32px;
      padding-top: 2px;
    }
    &__call {
      color: $main-blue;
      height: 24px;
      background-image: url(../img/phone-call.svg);

    }
    &__mail {
      color: $main-blue;
      height: 24px;
      background-image: url(../img/email-blue.svg);

    }

    @media (max-width: $breakpoint-sm) {
      margin: 20px 0 0 0;
      text-align: center;
      line-height: 1.4em;
      &__call {
        display: block;
        width: 100%;
        margin: 10px 0;
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }
}