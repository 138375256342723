.collapsible {
  background-color: #F6F6FB;
  color: #333;
  font-weight: normal;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 17px;
  margin: 10px 0 0 0;
  box-shadow: none;
  line-height: 2em;
  border: 1px solid rgba(#ccc, 0.5);
}

// .active {
//   background-color: white;
// }

.collapsible:hover {
  background-color: white;
}

.collapsible:after {
  content: '\002B';
  color: black;
  font-weight: normal;
  float: right;
  margin-left: 5px;
  font-size: 20px;
}

.activated:after {
  content: "\2212";
}

.content {
border-top: none;
border-left: 1px solid rgba(#ccc, 0.5);
border-right: 1px solid rgba(#ccc, 0.5);
border-bottom: 1px solid rgba(#ccc, 0.5);
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.faq {
    ol {
        list-style: decimal;
        list-style-position: inside;
        
        li {
            font-weight: bold;
            font-size: 1em !important;
            margin-bottom: 20px;

            p {
                margin: 10px 0;
                padding: 0 20px;
                font-weight: normal;
                font-size: 0.8em;
                line-height: initial;
            }
        }
    }
    
    ul {
        li {
            font-weight: bold;
            font-size: 1em !important;
            margin-bottom: 20px;
            p {
                margin: 10px 0;
                padding: 0;
                font-weight: normal;
                font-size: 0.8em;
                line-height: initial;
            }
        }
    }
    &__header {

    }

    &__desc {
        padding: 0;
        font-size: 0.8em;
        margin-bottom: 20px;
    }
} 