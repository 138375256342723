@import "../variables";

// .info-tooltip-wrapper {
//   position: absolute;
//   background: white;
//   border-radius: 5px;
//   border: 1px solid $table-border;
//   padding: 20px;
//   z-index: 1;
//   max-width: 360px;
//   display: none;
//   line-height: 1.4em;
//   font-size: 16px;
//   box-shadow: 0 0 30px rgba(black, 0.3);

//   span {
//     font-weight: bold;
//     margin-bottom: 5px;
//     display: block;
//     font-size: 18px;
//   }

//   p {
//     line-height: inherit;
//     font-size: inherit;
//   }
// }


/**
 * Tooltip Styles
 */

 span.tooltip {
  background-image: url(../img/information.svg);
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  top: 2px;
  margin-left: 4px;
 }

/* Add this attribute to the element that needs a tooltip */
[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  // -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  // filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 160px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #FFF;
  // background-color: rgba(255, 255, 255, 0.9);
  color: #000;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 12px;
  line-height: 1.5em;
  box-shadow: 0 0 5px #CCC;
}

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #FFF;
  border-top: 5px solid rgba(255, 255, 255, 1);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  // -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  // filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}