@import url('https://fonts.googleapis.com/css?family=PT+Serif');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600');

html {
	font-family: 'Open Sans', sans-serif !important;
	font-size: 1em; 
	line-height: 1.5em; 
	color: $gray; 
	-webkit-font-smoothing: antialiased;
}

@media (max-width: $breakpoint-sm) {
  html {
    font-size: 0.9em;
  }
}

@media (min-width: $breakpoint-lg) {
  html {
    font-size: 1.1em;
  }
}

h1, h2 { 
	font-family: 'PT Serif', serif;
}

h1 {
	font-size: 38px;
	line-height: 48px; 
	margin-bottom: 1rem; 
}
h2 {
	font-size: 28px;	
	line-height: 37px;
	margin-bottom: 1rem; 
}
h3 {
	font-size: 13px;
    // color: $gray;
    margin-bottom: 17px;
    display: inline-block;
    font-weight: 600;
}

p {
	margin-bottom: 1em;
}

p, li {
	font-size: 16px;
	line-height: 24px; 
	&.intro {
		font-size: 18px;
		line-height: 26px; 
	}
	&.subtitle {
		text-transform: uppercase;
		font-size: 14px;
		line-height: 22px;
	}
}


b, strong { font-weight: 700; } 

a {
	color: $secondary-color; 
	text-decoration: none; 
	transition: $transition;
	
	&:hover {
		color: $darker-secondary-color; 
		text-decoration: underline; 
	}

	&.nounderline:hover {
		text-decoration: none;
	}
}

.footer a {
	color: #CCC; 
}

.footer a:hover {
	color: #FFF; 
	text-decoration: none;
}

input, 
textarea {
	font-size: 0.75rem;
	line-height: 1rem;
	font-family: 'Open Sans', sans-serif;
}
label {
	font-size: 0.775rem;
	color: $gray; 
	// text-transform: uppercase;
	margin-bottom: 0.75em; 
	display: inline-block;
}
label.checkbox-label {
	color: inherit;
	text-transform: inherit;
}


.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.text-left { text-align: left; }
.text-center { text-align: center; }
.text-right { text-align: right; }

.text-gray { color: #999 !important; }

.text-small {
	font-size: 0.8rem; 
	line-height: 1.2rem; 
}

.text-white { color: white; } 

.ui-autocomplete {
	.ui-menu-item {
		font-size: 0.8em !important;
	}
}
