.contact-section {
    padding: 0 0 0 0 !important;
}

.contact-section {
    .sep {
        background: #BB0046;
        height: 2px;
        width: 70px;
        margin-bottom: 15px;
    }
    .info-box__header {
        margin-left: 0 !important;
    }
}