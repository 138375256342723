// Checkbox style
// .upload__label--secondary{

.checkbox-controlled-options { display: inline-block; }


    input[type="checkbox"] {
        display: none;
    }
    input[type="checkbox"] + span {
        position: relative;
        padding-left: 23px;
        line-height: 1.5em;
        display: block;

        .toggle-btn & {
            padding-left: 15px;
            padding-right: 15px;
        }

        &::before{
            content: '';
            border: 1px solid $border-gray;
            border-radius: 4px;
            height: 16px;
            width: 16px;
            position: absolute;
            left: 0;
            top: 2px;
            background-color: #FFF;

            // th & { background-color: #FFF; }
            tr:hover & { background-color: #FFF; }

            .toggle-btn & {
                display: none;
            }
        }
        &::after{
            content: '';
            width: 5px;
            height: 8px;
            border: 2px solid $main-blue;
            border-left: none;
            border-top: none;
            position: absolute;
            left: 6px;
            top: 5px;
            transform: rotatez(45deg);
            cursor: pointer;
            z-index: 2;
            display: none;

            .toggle-btn & {
                display: none !important;
            }
        }

        .checkbox-list & {
            margin-right: 12px;
        }
    }
    input[type="checkbox"]:checked + span {
        &::after{
            display: block;
        }
    }
    
    // Radio style
    
    input[type="radio"] {
        display: none;
    }
    input[type="radio"] + span {
        position: relative;
        padding-left: 23px;
        // white-space: nowrap;
        line-height: 1.5em;
        margin-right: 8px;
        
        &::before{
            content: '';
            border: 1px solid $light-blue;
            border-radius: 50%;
            height: 16px;
            width: 16px;
            position: absolute;
            left: 0;
            top: 0;
        }
        &::after{
            content: '';
            width: 6px;
            height: 6px;
            background: $main-blue;
            border-radius: 50%;
            position: absolute;
            left: 5px;
            top: 5px;
            z-index: 2;
            display: none;
        }
    }
    input[type="radio"]:checked + span {
        &::after{
            display: block;
        }
    }
// }
// .upload__label--datepicker {
    // .input-icon {
    //     display: inline-block;
    //     width: 130px;
    //     position: relative;
    //     margin: -8px 10px 0 10px;
    //     display: none;
    //     &.js-visible{
    //         display: inline-block;
    //     }
    //     img {
    //         position: absolute;
    //         right: 10px;
    //         top: 50%;
    //         transform: translateY(-50%);
    //         width: 16px;
    //         pointer-events: none;
    //     }
    //     input {
    //         width: 100%;
    //         height: 30px;
    //         margin: 0;
    //     }
    // }

    // .input-icon {
    //   display: inline-block;
    //   width: 130px;
    //   position: relative;
    //   margin: 0 10px;

      input.datepicker {
        // width: 100%;
        height: 30px;
        margin: 0;

        &::after {
            content: '';
            display: block;
            width: 30px;
            height: 30px;
            background-color: red;
            background-image: url(../img/calendar.svg);
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            width: 16px;
            pointer-events: none;
        }
      // }

      // img {
      //   position: absolute;
      //   right: 10px;
      //   top: 50%;
      //   transform: translateY(-50%);
      //   width: 16px;
      //   pointer-events: none;
      // }
    }

    @media (max-width: $breakpoint-lg) {
        flex-direction: column;
        .input-icon{
            margin-top: 10px;
        }
    }
// }


// CALENDAR
.ui-datepicker {
    z-index: 9999 !important;
}

.datepicker-container {
    width: auto;
    display: inline-block;
}

.ui-state-default, 
.ui-widget-content .ui-state-default, 
.ui-widget-header {
    border: none;
    background-color: $light-blue;
}

.ui-state-active, .ui-widget-content .ui-state-active {
    border: none;
    background-color: $secondary-color;
    color: #FFF;
}

// // CALENDAR