%text-style {
  font-size: 12px;
  color: $text-grey;
  float: left;
  margin: 0;
}

.button-wrapper {
  display: flex;
  overflow: hidden;

  a {
    align-items: center;
  }
}

.toggle-btn,
.filter-btn {
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 20px;
  border: 1px solid $table-border;
  overflow: auto;
  float: left;

  span {
    padding: 7px 10px;
    text-align: center;
    display: block;
    border-radius: 4px;
  }

}

.toggle-btn {
  background-color: white;

  label {
    @extend %text-style;

    input {
      position: absolute;
      visibility: hidden;

      &:hover + span {
        cursor: pointer;
        background-color: $light-blue-hover;
      }

      &:checked + span {
        background-color: $light-blue;
      }

      &:checked:hover + span {
        background-color: $light-blue;
      }

    }

  }

}

.filter-btn {
  background-color: $light-blue;
  @extend %text-style;
  display: flex;
  color: $filter-text;

  &__remove {
    background: none;
    padding: 0;
    margin: 0 5px 0 0;
    opacity: .7;

    &:hover {
      opacity: .5;
      cursor: pointer;
    }
  }
}

// .show-tooltip {
//   width: 16px;
//   height: 16px;
//   background-image: url(../img/information.svg);
//   background-repeat: no-repeat;
//   background-size: 16px;
//   display: inline-block;
//   background-color: transparent;
//   border: none;
//   margin: 0;
//   vertical-align: top;
//   margin-top: 5px;
//   margin-left: 5px;
//   opacity: .3;
//   transition: all .2s ease;

//   &:hover {
//     cursor: pointer;
//     opacity: 0.8;
//   }
// }

.download-btn {
  border: 1px solid $table-border;
  background: none;
  font-weight: bold;
  margin: 0;
  padding: 5px 10px;
  border-radius: 5px;
  transition: all .2s ease;

  &:hover {
    cursor: pointer;
    color: white;
    background: $secondary-color;
  }
}

.more-btn {
  border: none;
  margin: 0;
  width: 20px;
  height: 20px;
  padding: 5px 10px;
  margin-left: 10px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 20px;
  background-image: url(../img/down-arrow.svg);
  background-color: transparent;
  transition: all .2s ease;

  &:hover {
    cursor: pointer;
    opacity: .7;
  }
}

.select {
  position: relative;

  &__button {
    line-height: 2.2em;
    padding: 0;
    border: none;
    color: $text-grey;
    text-transform: uppercase;
    margin: 0;
    background-repeat: no-repeat;
    background-size: 10px;
    background-image: url(../img/down-arrow.svg);
    background-color: transparent;
    transition: all .2s ease;
    background-position: right;
    padding-right: 15px;

    &:hover {
      cursor: pointer;
    }
  }

  &__box {
    position: absolute;
    display: none;
    left: 0;
    top: 60px;
    z-index: 1;
    background-color: white;
    // padding: 20px;
    padding: 16px 6px 16px 16px;
    width: 100%;
    box-shadow: 0 10px 10px 0px rgba(0,0,0, 0.1);

    &.visible {
      display: block;
    }

    a {
      display: block;
      width: 100%;
      background-image: url(../img/right-arrow.svg);
      background-repeat: no-repeat;
      background-size: 16px;
      margin-bottom: 15px;
      background-position: right;
      transition: all .2s ease;

      &:hover {
        opacity: .5;
      }
    }

  }
}
.button--table{
  font-size: 12px;
  font-weight: 400;
  padding: .5rem 1rem
}