@import "../variables";

.raport-table, 
.admin-table {
  font-family: 'Open Sans', sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 12px;
  line-height: 1.4em;
  border-radius: 5px;

  & * {
    font-size: 12px;
  }

  &__sorting {
    // display: none;
    text-transform: uppercase;
    color: $secondary-color;
    background: none;
    border: none;
    display: inline-block;
    // margin: 0 0 0 5px;
    padding: 4px 16px 4px 0;
    margin-bottom: 0;
    // height: 12px;
    // width: 12px;
    // min-width: 10px;
    // background-size: 10px;
    background-repeat: no-repeat;
    background-position: right center;
    transition: all .2s ease;
    // padding: 4px 8px;
    cursor: pointer;

    &--desc {
      font-weight: 600;
      background-image: url(../img/arrow-down-blue.svg);
    }

    &--asc {
      font-weight: 600;
      background-image: url(../img/arrow-up-blue.svg);
    }

    &:hover {
      cursor: pointer;
      opacity: .7;
    }

  }

  &__type {
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 14px 20px;
    padding-left: 40px !important;

    &.news {
      background-image: url(../img/newspaper.svg);
    }

    &.alert {
      background-image: url(../img/bell.svg);
    }

    &.flag {
      background-image: url(../img/flag.svg);
    }

    &.file {
      background-image: url(../img/file.svg);
    }

    span {
      line-height: 4em;
    }

  }

  tr {

    &:hover {
      background-color: $light-blue-hover;
    }

    td, th {
      padding: 15px;
      border-top: 1px solid $table-border;
      border-bottom: 1px solid $table-border;

      &:first-child {
        border-left: 1px solid $table-border;
      }
      &:last-child {
        border-right: 1px solid $table-border;
      }

    }

    &.admin-table__content {
      td {
        padding-left: 38px;
      }
    }

    th {
      text-align: left;
      background-color: $table-header-bg;
      color: $text-blue;
      text-transform: uppercase;

      &.date {
        color: $secondary-color;
      }

      &:focus {
        outline: none;
      }
    }

  }

  .title-cell,
  .topic-cell, 
  .actions-cell {
    height: 35px;
    display: block;
    overflow: hidden;
    // display: flex;
    // flex-direction: row;
    line-height: 150%;

    
  }

  .title-cell {
    display: flex;
    flex-direction: column;
  }
  .title-cell .title {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 1em;

    &.unread {
      font-weight: bold;
    }

  }

  .title-cell .description {}

  .actions-cell {
    .share-options {
      margin-top: 24px;

      .share-option {
        display: block;
        margin: 16px 0;
      }
    }
  }
  

  &__title {
    width: 30%;

    &.search-cell {
      width: 45%;
    }
  }

  &__date {
    width: 8%;
    &.search-cell {
      width: 10%;
    }
  }

  &__type,
  &__topic{
    width: 27%;

    .topic-cell {
      position: relative;

      p {
        font-size: inherit;
        line-height: inherit;
        margin-bottom: 50px;
      }

      
    }
  }

  &__type {
    &.search-cell {
      width: 12%;
    }
  }

  &__topic {
    width: 28%;

    &.search-cell {
      width: 19%;
    }
  }

  &__actions {
    width: 20%; 
    padding-top: 30px;

    .raport-table.four-cols & {
      width: 10%;
    }

    &.search-cell {
      width: 18%;
    }

    .share-option {
        background-color: transparent;
        border: none;
        color: $main-blue;
        background-repeat: no-repeat;
        background-size: 16px 16px;
        background-position: 0 0;
        padding: 0 0 0 23px;
        transition: all .2s ease;
        width: auto;

        &.fav {
          background-image: url(../img/fav.png);
        }

        &.share {
          background-image: url(../img/share.png);
        }

        &:hover {
          cursor: pointer;
        }
      }

  }
    
}
