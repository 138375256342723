.raport {
  padding-bottom: 75px;

  &__header {
    display: block;
    border-bottom: 1px solid $table-border;
    width: 100%;
    padding-bottom: 7px;

    h2 {
      float: left;
    }

    .toolbox {
      display: inline-block;
    }

    .filter {
      float: right;
      // margin: 4px 0;
    }
  }

  .selected-filters {
    display: block;
    padding-top: 10px;

    &__title {
      text-transform: uppercase;
      font-weight: bold;
      color: #777777;
      font-size: 12px;
      display: inline-block;
    }

    &__clear {
      background: none;
      color: $secondary-color;
      text-decoration: underline;
      font-size: 10px;
      border: none;
      padding: 0;
      display: inline-block;
      margin: 0;

      &:hover {
        cursor: pointer;
        text-decoration: none;
      }
    }

    &__holder {
      padding: 10px 0;
      overflow: hidden;
    }

  }

}

.meta {
  margin-left: -15px;
  margin-top: 12px;
  margin-bottom: 24px;

  li {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
    border-right: 1px solid #DDD; 
    font-size: 13px;
    line-height: 14px;

    &:last-of-type {
      border-right: none;
    }

    .icon {
      height: 14px;
      width: auto;
      padding-right: 6px;
      margin-bottom: -2px;
    }
  }
}