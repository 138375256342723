.footer {
  .level-1 {
    background-color: #2B2B2B;
    color: #D9D9D9;
    padding-top: 48px;
    padding-bottom: 32px;
    h1 {
      color: #FFF;
      font-size: 28px;
      line-height: 37px;
    }
    .logo {
      float: right;
      margin-top: 8px;
    }
  }
  .level-2 {
    background-color: #313131;
    color: #6A6A6A;
    font-size: 11px;
    line-height: 16px;
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
