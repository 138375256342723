*,
*::before,
*::after {
  box-sizing: border-box;
}

body.homepage {
  background-color: #FFFFFF;
}

h1::after, 
h2.underlined::after {
  content: "";
  display: block;
  height: 2px;
  width: 80px;
  border-radius: 1px;
  background-color: #BB262A;
  margin-top: 16px;
  margin-bottom: 18px;

  &.white::after {
    background-color: #FFF;
  }
}
h2.underlined.white::after {
  background-color: #FFF;
}

.top {
  .logo {
    margin-top: 22px;
    margin-bottom: 16px;
  }
  // .flag {
  //   margin-top: 30px;
  //   margin-left: 20px;
  // }
  .form-login {
    position: absolute;
    top: 60px;
    right: 0;
    z-index: 3;
    padding: 32px 40px;
    border-radius: $radius;
    background-color: #FFFFFF;
    box-shadow: $darker-shadow;
  }
  button.is-active {
    background-color: #FFFFFF;
    color: $secondary-color;
    box-shadow: 0 0 1px 0 rgba(0,0,0,0.30);
  }
}

.show-more {
  height: auto !important;
  // display: block !important;
}

.rotated {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.logged-out-page {
  margin-top: 100px;
  border: none;

  &__icon {
    max-width: 250px;
    opacity: 0.5;
  }

  &__content {
    margin-top: 50px;
  }

}

.additional-option {
  font-weight: normal !important;
  text-decoration: none !important;
  text-transform: none !important;
  // color: black;
  // margin-left: 15px;
  line-height: 1.3em;
  margin-bottom: 40px;

  input {
    margin-left: -15px;
  }

  span {
    color: black;
  }

  &.form-login__errormessage {
    span {
      color: $primary-color;
    }
  }

}

.sidebar-custom-header {
  font-size: 1.2em;
  line-height: 1.4em;
  margin-bottom: 40px;
}
.custom-toggle-btn {
  min-width: 130px;
  label {
    opacity: 0.5;
    display: flex;
    img {
      width: 16px;
      height: 16px;
      margin: 5px 0 0 10px;
    }
  }
}

.email-wrapper {
  display: flex;
  margin-top: 20px;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.checkbox-table {
  display: inline;
  float: left;
}
// Admin views helpers
.container--custom{
  @media (min-width: 768px){
    max-width: 960px;
  }
  @media (min-width: 992px){
    max-width: 960px;
  }
  @media (min-width: 1200px){
    max-width: 1140px;
  }
}
.d-flex{
  display: flex;
}
.flex-align-start{
  align-items: flex-start;
}
.flex-align-center{
  align-items: center;
}
.flex-justify-center{
  justify-content: center;
}
.flex-column{
  flex-direction: column;
}
.m-left{
  &-0{
    margin-left: 0!important;
  }
  &-15{
    margin-left: 15px!important;
  }
}
.m-right{
  &-0{
    margin-right: 0!important;
  }
  &-15{
    margin-right: 15px!important;
  }
}
.hidden{
  display: none;
  max-height: 0;
  width: 0;
  visibility: hidden;
  opacity: 0;
}

ul.bullets {
  // list-style-type: disc;
  margin-left: 16px;
  list-style: none;
  li::before {
    content: "•"; color: #CCC;
    display: inline-block; 
    font-weight: bold;
    width: 1em;
    margin-left: -1em;
  }
}

.watermark-404 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  transform: translate(0, 50%);
}

ul.notifications {
  position: fixed;
  right: 0;
  top: 164px;
  z-index: 99999;
  pointer-events: none;

  li.notification {
    pointer-events: auto;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600; 
    padding: 17px 58px 17px 32px;
    border-radius: 5px 0 0 5px;
    margin-bottom: 12px;
    background-color: #FFF;
    box-shadow: 0 0 3px 0 rgba(0,0,0,0.3);
    position: relative;
    right: -100%;

    transition: all 300ms ease-in-out;

    &.error {
      background-color: $fail-color;
      color: #FFF;
    }
    &.success {
      background-color: $success-color;
      color: #FFF;
    }

    &.is-visible {
      right: 0;
    }

    & img.close {
      display: inline-block;
      position: absolute;
      right: 12px;
      top: 9px;
      padding: 12px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

ul.accordion {

  li {
    font-size: 12px;
    border-bottom: 1px solid $light-blue;

    .acc-header {
      display: block;
      padding-top: 8px;
      padding-bottom: 8px;
      background-image: url(../img/down-arrow.svg);
      background-repeat: no-repeat;
      background-position: right center;
      background-size: 16px;
      font-weight: 600;
      color: $secondary-color;
      cursor: pointer; 

      &.open {
        background-image: url(../img/up-arrow.svg);
      }
    }
    .acc-body {
      padding-bottom: 8px;
    }
  }
}