html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

h1::after {
  content: "";
  display: block;
  height: 2px;
  width: 80px;
  border-radius: 1px;
  background-color: #BB262A;
  margin-top: 16px;
  margin-bottom: 18px;
}

.h-no-line::after {
  display: none !important;
}


.top {
  .logo {
    display: block;
    margin-top: 22px;
    margin-bottom: 16px;
  }
  .flag {
    margin-top: 30px;
    margin-left: 20px;
  }
  .form-login {
    position: absolute;
    top: 60px;
    right: 0;
    z-index: 3;
    padding: 32px 40px;
    border-radius: $radius;
    background-color: #FFFFFF;
    box-shadow: $darker-shadow;
  }
  &.has-bottom-line::before { 
    display: block;
    width: 100%;
    z-index: 0;
    border-bottom: 1px solid $primary-color; 
    position: absolute;
    left: 0;
    right: 0;
    top: 80px;

  }
  
  button.is-active {
    background-color: #FFFFFF;
    color: $secondary-color;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.30);
  }

  &__menu {
    list-style-type: none;
    margin: 0;
    padding: 0;
    color: $text-grey;
    display: flex;
    justify-content: flex-end;

    li {
      padding: 20px 15px;
      margin-top: 0.5em;
      display: block;

      &.active {
        box-shadow: 0 0 3px 3px rgba(black, 0.05);
      }

      a {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        transition: all .15s linear;
        color: $text-grey;
        opacity: .8;
        margin: 4px 0 4px -4px;
        padding: 8px 0 8px 4px;
        background-position: right 8px center;

        &:hover {
          opacity: 1;
          text-decoration: none;
        }
      }

      select {
        font-family: 'Open Sans', sans-serif;
        background: none;
        border: none;
        text-transform: uppercase;
        padding: 0;
        color: $text-grey;

      }

      .select__box {
        a:hover {
          background-color: $light-blue-hover;
        }
      }

      .account-link {
        border: 1px solid #E8EAF6;
        padding: 5px 10px;
        border-radius: 5px;

        span {
          display: inline-block;
          margin-right: 15px;
        }

        img {
          width: 12px;
          display: inline-block;
        }

        &:hover {
          border: 1px solid $text-grey;
        }
      }

    }
  }
}