.default {
  padding-bottom: 75px;
  min-height: 70vh;

  &__message {
    background-color: #E8EAF6;
    padding: 30px;
    overflow: hidden;
    margin-bottom: 20px;

    .msg {
      float: left;
      background: url(../img/padlock.svg) no-repeat;
      background-size: 32px 32px;
      font-size: 1.3em;
      font-family: "Times New Roman";
      padding-left: 40px;
      height: 32px;
      margin: 0;
      line-height: 1.6em;
    }

    button {
      float: right;
      margin: 0;
    }
  }

  &__wrapper {
    margin-bottom: 70px;
  }

  .breadcrumb {
    margin: 15px 0;
    padding: 10px 16px;
    list-style: none;
    li {
      display: inline;
      font-size: 18px;
      a {
        font-family: 'Open Sans', sans-serif;
        font-size: 14px;
        color: $text-grey;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    li + li:before {
      padding: 8px;
      color: $text-grey;
      content: "/\00a0";
    }

  }

  &__header {
    margin: 40px 0 0 0;
    overflow: hidden;

    &.pink-header {
      h2 {
        color: #941E44;
      }
    }

    h2 {
      float: left;
      color: $primary-color;
    }

    .filter {
      display: flex;
      align-items: center;
      float: right;
      background: none;
      border: none;
      box-shadow: none;
      color: $text-grey;
      font-weight: normal;
      padding: 5px 0;
      vertical-align: top;

      img {
        margin-left: 5px;
        width: 16px;
      }
    }
  }

  .download-btn {
    border: 1px solid $table-border;
    background: none;
    font-weight: bold;
    margin: 0;
    padding: 5px 10px;
    border-radius: 5px;
    transition: all .2s ease;

    &:hover {
      cursor: pointer;
      color: white;
      background: $secondary-color;
    }
  }

  .more-btn {
    border: none;
    margin: 0;
    width: 20px;
    height: 20px;
    padding: 5px 10px;
    margin-left: 10px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 20px;
    background-image: url(../img/down-arrow.svg);
    background-color: transparent;
    transition: all .2s ease;

    &:hover {
      cursor: pointer;
      opacity: .7;
    }
  }
  .collapse-btn {
    border: none;
    margin: 0;
    width: 20px;
    height: 20px;
    padding: 5px 10px;
    margin-left: 10px;
    display: inline-block;
    background-repeat: no-repeat;
    background-size: 20px;
    background-image: url(../img/down-arrow.svg);
    background-color: transparent;
    transition: all .2s ease;

    &:hover {
      cursor: pointer;
      opacity: .7;
    }
    &.js-active{
      transform: rotate(180deg);
    }
  }

  .pagination {
    margin: 30px auto;
    width: 200px;
    display: flex;
    font-size: 0.8em;
    justify-content: center;

    a {
      padding: 5px 8px;
      vertical-align: bottom;

      &:hover {
        text-decoration: underline;
      }
    }

    span {
      padding-top: 6px;
    }

    .back,
    .forward {
      border: 1px solid $table-border;
      transition: all .2s ease;

      &:hover {
        color: white;
        background-color: $secondary-color;
        border-color: $secondary-color;
        text-decoration: none;
      }
    }
  }

  &__sidebar {

    .sidebar-header {
      margin: 10px 0 25px 0;

      h2 {
        font-size: 21px;
        line-height: 27px;
        margin-top: 18px;
      }
    }

  }

  .single-block {
    border: 1px solid $table-border;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 20px;

    &__header {
      // display: block;
      color: #1B237E;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;

      &.news,
      &.file,
      &.target,
      &.flag {
        line-height: 1.4em;
        height: 24px;
        // background-repeat: no-repeat;
        // background-size: 24px;
        // background-position: 0 50%;
        // padding-left: 32px;
      }

      // &.news {
      //   background-image: url(../img/newspaper.svg);

      // }
      // &.file {
      //   background-image: url(../img/file.svg);

      // }
      // &.target {
      //   background-image: url(../img/target.svg);

      // }
      // &.flag {
      //   background-image: url(../img/flag.svg);

      // }
    }

    &__content {
      font-size: 14px;
      line-height: 21px;
      margin: 1em 0 0.5em;
    }

    &__more {
      display: block;
      font-size: 0.7em;
      text-decoration: underline;
      margin-top: 8px;
      padding: 8px 0;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .question-block {
    border: 1px solid $table-border;
    padding: 40px 20px;
    display: flex;
    border-radius: 5px;
    margin-left: 0;
    margin-right: 0;

    h3 {
      font-size: 1.4em;
      margin-bottom: 20px;
    }

    p {
      font-family: 'Open Sans', sans-serif;
    }

    div:last-child {
      position: relative;
      display: block;

      .button {
        position: absolute;
        top: 30%;
        left: 40%;
        transform: translate(-10%, -50%);
        width: auto;

        &:hover {
          color: white;
        }
      }

    }

    &--toip {
      h3 {
        font-size: 1.2em;
        margin-bottom: 20px;
      }
    }

  }


}

.toip-section {
  margin: 50px 0;
  &__icon {
    max-width: 250px;
    opacity: 0.09;
  }

  .infobank {
    text-transform: uppercase;
    color: #941E44;
    font-family: 'Times New Roman', Times, serif;
    font-size: 1.3em;

  }
}

.toip-selected {
  padding: 20px !important;
  h3 {
    display: inline-block;
    margin-right: 40px;
  }
  &__btn {
    position: initial !important;
    display: inline-block;
    top: auto !important;
    left: auto !important;
    transform: none !important;
  }

  &__news-blocks {
    .single-new-block {
      float: left;
      border-right: 1px solid rgba(#999999, 0.5);
      padding-left: 30px;
      &:last-child {
        border: none;
      }
      &__title {
        font-size: 1em;
        font-weight: bold;
        margin: 10px 0;
        display: block;
      }

      &__desc {
        font-size: 0.8em;
      }

      &__button {
        display: block;
        overflow: hidden;
        .single-arrow {
          margin-top: 10px;
          width: 26px;
        }
      }

    }
  }
}