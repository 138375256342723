.admin__header{
  .filter{
    margin: 0;
  }
}
.admin-table{
  tr{
    td{
      &:last-child {
        border-left: 1px solid $table-border;
      }
    }
    th{
      span{
        white-space: nowrap;
      }
    }
    &.blocked {
      color: #A0A0A0;

      a, 
      label.item-name {
        color: #A0A0A0;
      }

      span.surname, 
      span.group-name {
        padding-right: 16px;
        background: url("../img/padlock2.svg") no-repeat;
        background-size: 12px 12px;
        background-position: right 1px;
      }
      
    }
    
  }
  label{
    margin-bottom: 0;
  }
  &__body{
    .admin-table__content{
      display: none;
    }
    .collapse-content{
      height: 35px;
      overflow: hidden;
    }
    &.js-active{
      background-color: $lightblue;
      tr{
        &:first-child{
          td{
            &:last-child {
              border-bottom: 1px solid transparent;
            }
          }
        }
      }
      .admin-table__content{
        display: table-row;
      }
      .collapse-content{
        height: auto;
        overflow: visible;
      }
    }
  }
  &__row{
    &:not(:last-child){
      margin-bottom: 15px;
    }
  }
  &__checkbox{
    margin-right: 5px;
  }

  .table__button{
    &--text{
      font-size: 12px;
      color: $main-blue;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .button--small{
    margin-left: 0;
  }
}

.col-1of5{
  max-width: 20%;
  flex: 1;
  padding: 0 15px;
  margin-right: 15px;
  &:last-child{
    margin-right: 0;
  }
  @media (max-width: $breakpoint-lg) {
    max-width: 50%;
    flex: unset;
    margin-bottom: 15px;
  }
}

.col-1of6{
  max-width: calc(100%/6);
  flex: 1;
  padding: 0 15px;
  margin-right: 15px;
  &:last-child{
    margin-right: 0;
  }
  @media (max-width: $breakpoint-lg) {
    max-width: 50%;
    flex: unset;
    margin-bottom: 15px;
  }
}
.heading{
  &--small{
    font-size: 11px;
    color: $lightgray;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
}
@import "../variables";

.admin-popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9;
  &__list{
    margin-top: 20px;
    li{
      display: flex;
      justify-content: space-between;
      border: 1px solid #E8EAF6;
      border-radius: 4px;
      margin-bottom: 8px;
      padding: 10px;
    }
  }
  &__remove-item{
    cursor: pointer;
  }
  .popup-bg {
    background-color: rgba(0,0,0,0.5);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  .popup-body {
    display: block;
    width: 100%;
    max-width: 500px;
    background-color: #FFFFFF;
    padding: 32px;
    color: $gray;
    border-radius: $radius;
    box-shadow: $shadow;
    position: relative;
    z-index: 100;
    transition: all 300ms ease-out;
    max-height: 600px;
    overflow-y: auto;
    h2 {
      margin-bottom: 16px;
    }
    &.hide {
      margin-top: 48px;
    }

    @media (max-width: $breakpoint-md) {
      width: 100%;
      min-width: auto;
    }

  }
  .popup-close {
    position: absolute;
    right: 20px;
    top: 36px;
    padding: 8px;
    cursor: pointer;
  }

  .filter-section, .section {
    margin-bottom: 25px;
    margin-right: 10px;
    display: inline-block;

    &__header {
      display: block;
      text-transform: uppercase;
      font-weight: bold;
      color: #777777;
      font-size: 12px;
      margin-bottom: 10px;
    }

    label {
      color: black;
      text-transform: none;
    }

    input {
      margin: 0 10px;
    }

    // .input-icon {
    //   display: inline-block;
    //   width: 130px;
    //   position: relative;
    //   margin: 0 10px;

    //   input {
    //     width: 100%;
    //     height: 30px;
    //     margin: 0;
    //   }

    //   img {
    //     position: absolute;
    //     right: 10px;
    //     top: 50%;
    //     transform: translateY(-50%);
    //     width: 16px;
    //     pointer-events: none;
    //   }
    // }

    .inline-input {
      display: inline-block;
    }
  }

  .error-section {
    .button-section {
      display: flex;
      justify-content: flex-start;
    }
  }

  hr {
    background: $table-border;
    height: 1px;
    border: none;
  }

  .button-section {
    display: flex;
    justify-content: flex-end;
  }
}