.upload-drop-zone {
    height: 280px;
    border-width: 2px;
}

.upload-drop-zone {
    color: #ccc;
    border-style: dashed;
    border-color: $light-blue;
    text-align: center;
    background: #FDFDFD;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
}
.upload-drop-zone.drop {
    border-color: #767DA3;
}
.upload{
    &__wrapper{
        background: #FAFAFA;
        border-radius: 4px;
        padding: 24px;
    }
    &__open-dialog{
        text-decoration: underline;
        cursor: pointer;
    }
    &__icon{
        margin-bottom: 24px;
    }
    &__files{
        margin-top: 30px;
    }
    &__single{
        margin-bottom: 16px;
    }
    &__header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        border-radius: 4px;
        background: #FFFFFF;
        border: 1px solid $light-blue;
        height: 70px;
        padding: 0 40px;
        @media (max-width: $breakpoint-lg) {
            padding: 0 20px;
        }
        &.js-active{
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    &__progress{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background: $light-blue;
        z-index: 1;
        transition: width .3s;
    }
    &__title{
        z-index: 2;
        @media (max-width: $breakpoint-sm) {
            flex-direction: column;
        }
    }
    &__subtitle{
        font-size: 12px;
        margin-left: 13px;
        @media (max-width: $breakpoint-sm) {
            margin-left: 0;
            white-space: nowrap;
        }
    }
    &__actions{
        z-index: 2;
        @media (max-width: $breakpoint-sm) {
            margin-left: 15px;
            display: flex;
            align-items: center
        }
    }
    &__settings{
        padding: 30px 40px 0 40px;
        border: 1px solid $light-blue;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        display: none;
        border-top: 0;
        &.js-active{
            display: block;
        }
        @media (max-width: $breakpoint-lg) {
            padding: 20px 15px 0 15px;
        }
        input{
            margin-bottom: 0;
            margin-top: 0;
        }
        select{
            width: 100%;
        }
        .input-group{
            label:not(:last-child){
                margin-right: 15px;
            }
            @media (max-width: $breakpoint-md) {
                flex-direction: column;
            }
        }
        .checkbox-group{
            // .title{
            //     font-size: 13px;
            //     color: $gray;
            //     margin-bottom: 17px;
            //     display: inline-block;
            //     font-weight: 600;
            // }

            &__box {
                margin-bottom: 16px;
            }
        }
        .link-small{
            font-size: 12px;
            color: $main-blue;
            text-decoration: underline;
            align-self: flex-start;
            margin-top: 8px;
        }
    }
    &__settings-row{
        margin-bottom: 24px;
    }
    &__label{
        font-size: 13px;
        color: $lightgray;
        text-transform: uppercase;
        span{
            display: inline-block;
            margin-bottom: 8px;
        }
        &--secondary{
            font-size: 12px;
            color: $gray;
            text-transform: none;
            display: flex;
            align-items: center;
        }
    }
    &__input-file{
        .open-upload{
            font-size: 16px;
            color: $main-blue;
            text-decoration: underline;
            cursor: pointer;
            margin-top: 10px;
            @media (max-width: $breakpoint-sm) {
                margin-top: 0;
            }
        }
        @media (max-width: $breakpoint-md) {
            margin-top: 24px;
        }
    }
    &__buttons{
        .button{
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}