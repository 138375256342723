.privacy {
    &__header {

    }
    &__list {
        list-style-type: decimal !important;
        display: block;
        padding-left: 20px; 

        li {
            margin-bottom: 15px;
        }
    }
}