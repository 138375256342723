.select2{
    width: 100%;
    margin-bottom: 10px;
    .select2-selection.select2-selection--single{
        outline: none;
        border: 1px solid #E8EAF6;
        border-radius: 4px;
        height: 36px;
    }
    .select2-selection--single .select2-selection__rendered{
        display: flex;
        align-items: center;
        height: 36px;
        color: #212121;
        font-size: 12px;
    }
    .select2-selection__placeholder{
        font-size: 12px;
        color: #212121;
    }
    .select2-selection__arrow{
        height: 36px!important;
        right: 5px!important;
        b{
            border-color: $main-blue transparent transparent transparent!important
        }
    }
}
.select2-container--open .select2-selection--single .select2-selection__arrow b{
    border-color: transparent transparent $main-blue transparent!important;
}
.select2-dropdown{
    border: 1px solid #E8EAF6;
}
.select2-results__option{
    color: #212121;
    font-size: 12px;
}