$breakpoint-xs: 0;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$radius: 3px;
$shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
$darker-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.20);
$large-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.2);
$border: 1px solid #F1F1F1;
$padding-s: 5px;
$padding: 10px;
$padding-l: 15px;
$padding-div: 30px;
$padding-div-md: 40px;
$transition: all 250ms ease-out;
$transition-reveal: all 300ms ease-out;
$gradient-red: linear-gradient(90deg, #780F31 0%, #FF568C 100%);
$gradient-blue: linear-gradient(90deg, #3F4DEE 0%, #09203F 100%);
$main-blue: #1b237e;


// Media queries
$desktop: "(min-width: 1025px)";
$tablets-1024: "(min-width: 768px) and (max-width: 1024px)";
$tablets-768: "(min-width: 481px) and (max-width: 767px)";
$mobile: "(min-width: 320px) and (max-width: 480px)";

@mixin debug($color) {
  border: 1px solid $color;
}

.debug {
  border: 1px solid red;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}