@import "variables";

// Primary colors
// -------------------------
// $primary-color:  	#A52439; //red
$primary-color:  	#911c42; //new red
$secondary-color:  	#1B237E; //blue
$menu-bg:           #b80051;

$white:          	#EEEEEE; 
$gray:           	#212121;
$black:          	#272727;

$body-background: 	#FAFAFA;



$lightgray: 	    #616161; 
$lightblue:         #F9F9FD;

// Color variations
// -------------------------
$darker-primary-color:    	darken($primary-color, 10%); 
$darker-secondary-color:    darken($secondary-color, 10%); 
$darker-white:    			darken($white, 5%); 

$dark-gray:      darken($gray, 24%); 
$darker-gray:    darken($gray, 8%); 
$light-gray:     lighten($gray, 48%); 
$lighter-gray:   lighten($gray, 84%);
// $border-gray:    #e0e0e0;
$border-gray:    #d6d6d6;
$text-grey:      #424242;

$table-header-bg: #E8EAF6;
$table-border: #E8EAF6;
$text-blue: #1B237E;

$light-blue: #E8EAF6;
$light-blue-hover: #F4F5FB;
$filter-text: #78729e;


// Success/fail colors
// -------------------
$success-color: #2FA97A;
$fail-color: $primary-color;

$success-color-light: lighten(#2FA97A, 50%);
$fail-color-light: lighten($primary-color, 50%);
