/* Style the tab */
.tab {
    overflow: hidden;
    border-bottom: 1px solid rgba(#ccc, 0.4);
}

/* Style the buttons inside the tab */
.tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 20px 16px;
    transition: 0.3s;
    font-size: 17px;
    &:first-child {
        border-left: 1px solid rgba(#ccc, 0.4);
    }
    border-top: 1px solid rgba(#ccc, 0.4);
    border-bottom: 0;
    border-right: 1px solid rgba(#ccc, 0.4);
}

/* Change background color of buttons on hover */
.tab button:hover {
    background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
    background-color: #ccc;
}

    .work-place {
        width: 50px;
        height: 50px;
        float: right;
        display: inline-block;

        &--custom {
            position: absolute;
            right: 0;
        }
    }

/* Style the tab content */
.tabcontent {
    display: none;
    padding: 40px;
    border: 1px solid rgba(#ccc, 0.4);
    border-top: none;

    h2 {
        display: inline-block;
        vertical-align: top;
    }



    .remove-account {
        padding-left: 0;
        padding-right: 0;
    }

    .access-block {
        h2 {
            margin-bottom: 5px;
        }
        p {
            font-size: 0.8em;
            margin: 10px 0;
        }
        .access-block-info {
            &__icon {
                width: 18px;
                position: relative;
                top: 3px;
            }
            &__title {
                margin-right: 10px;
            }
            button {
                font-size: 0.6em;
                padding: 8px 20px;
                margin: 0;
            }
        }
    }

    .auth-section {
        padding: 0 !important;

        .auth-box {
            padding: 0 !important;
            border: none;
        }

        .raports-header {
            line-height: 1.4em;
            font-size: 0.8em;
            display: block;
            margin-bottom: 10px;
        }
    }

    .auth-section .interests-box .form-login__row-bottom select {
        width: 200px !important;
    }

    .auth-section .interests-box {
        margin: 0 !important;
    }
}

.change-pass {
    font-size: 0.6em;
    text-transform: uppercase;
    text-decoration: underline;
}

.tablinks {
    min-width: 200px;
    padding: 0 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.9em !important;
    margin: 0;
    box-shadow: none;
    color: #666666;
    font-weight: normal;
    background: #F6F6FB !important;

    &.active {
        background: white !important;
    }
}

.top-row {
    margin-bottom: 60px;
}

.bot-row {
    h2 {
        color: #941E44;
    }
}