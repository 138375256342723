.search {
  padding-top: 20px;

  &__header {
    margin: 10px 0 10px 0;
    overflow: hidden;
  }

  h2 {
    display: inline-block;
  }

  &__data {
    display: inline-block;
    margin-left: 10px;
    line-height: 2.7em;
  }

  .not-found {
    display: flex;
    align-items: center;

    img {
      opacity: .1;
      width: 128px;
      margin: 30px;
    }

    &__wrapper {
      .error-title {
        font-size: 22px;
        display: block;
      }
      .error-subtitle {
        margin: 20px 0;
        display: block;
      }

    }
  }

}

.bottom-section {
  margin-top: 40px;
}